/*undefined*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.EmailSmsLogFilter = (function() {

    /**
     * Namespace EmailSmsLogFilter.
     * @exports EmailSmsLogFilter
     * @namespace
     */
    var EmailSmsLogFilter = {};

    EmailSmsLogFilter.EmailSmsLogFilterModel = (function() {

        /**
         * Properties of an EmailSmsLogFilterModel.
         * @memberof EmailSmsLogFilter
         * @interface IEmailSmsLogFilterModel
         * @property {string|null} [dateFrom] EmailSmsLogFilterModel dateFrom
         * @property {string|null} [dateTo] EmailSmsLogFilterModel dateTo
         * @property {string|null} [type] EmailSmsLogFilterModel type
         * @property {string|null} [status] EmailSmsLogFilterModel status
         * @property {string|null} [sortColumn] EmailSmsLogFilterModel sortColumn
         * @property {string|null} [sortOrder] EmailSmsLogFilterModel sortOrder
         * @property {number|null} [pageIndex] EmailSmsLogFilterModel pageIndex
         * @property {number|null} [pageSize] EmailSmsLogFilterModel pageSize
         * @property {string|null} [quoteNo] EmailSmsLogFilterModel quoteNo
         * @property {string|null} [rego] EmailSmsLogFilterModel rego
         * @property {string|null} [claimNo] EmailSmsLogFilterModel claimNo
         * @property {string|null} [direction] EmailSmsLogFilterModel direction
         */

        /**
         * Constructs a new EmailSmsLogFilterModel.
         * @memberof EmailSmsLogFilter
         * @classdesc Represents an EmailSmsLogFilterModel.
         * @implements IEmailSmsLogFilterModel
         * @constructor
         * @param {EmailSmsLogFilter.IEmailSmsLogFilterModel=} [p] Properties to set
         */
        function EmailSmsLogFilterModel(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        /**
         * EmailSmsLogFilterModel dateFrom.
         * @member {string|null|undefined} dateFrom
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.dateFrom = null;

        /**
         * EmailSmsLogFilterModel dateTo.
         * @member {string|null|undefined} dateTo
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.dateTo = null;

        /**
         * EmailSmsLogFilterModel type.
         * @member {string|null|undefined} type
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.type = null;

        /**
         * EmailSmsLogFilterModel status.
         * @member {string|null|undefined} status
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.status = null;

        /**
         * EmailSmsLogFilterModel sortColumn.
         * @member {string|null|undefined} sortColumn
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.sortColumn = null;

        /**
         * EmailSmsLogFilterModel sortOrder.
         * @member {string|null|undefined} sortOrder
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.sortOrder = null;

        /**
         * EmailSmsLogFilterModel pageIndex.
         * @member {number|null|undefined} pageIndex
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.pageIndex = null;

        /**
         * EmailSmsLogFilterModel pageSize.
         * @member {number|null|undefined} pageSize
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.pageSize = null;

        /**
         * EmailSmsLogFilterModel quoteNo.
         * @member {string|null|undefined} quoteNo
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.quoteNo = null;

        /**
         * EmailSmsLogFilterModel rego.
         * @member {string|null|undefined} rego
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.rego = null;

        /**
         * EmailSmsLogFilterModel claimNo.
         * @member {string|null|undefined} claimNo
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.claimNo = null;

        /**
         * EmailSmsLogFilterModel direction.
         * @member {string|null|undefined} direction
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        EmailSmsLogFilterModel.prototype.direction = null;

        // OneOf field names bound to virtual getters and setters
        var $oneOfFields;

        /**
         * EmailSmsLogFilterModel _dateFrom.
         * @member {"dateFrom"|undefined} _dateFrom
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_dateFrom", {
            get: $util.oneOfGetter($oneOfFields = ["dateFrom"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _dateTo.
         * @member {"dateTo"|undefined} _dateTo
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_dateTo", {
            get: $util.oneOfGetter($oneOfFields = ["dateTo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _type.
         * @member {"type"|undefined} _type
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_type", {
            get: $util.oneOfGetter($oneOfFields = ["type"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _status.
         * @member {"status"|undefined} _status
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_status", {
            get: $util.oneOfGetter($oneOfFields = ["status"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _sortColumn.
         * @member {"sortColumn"|undefined} _sortColumn
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_sortColumn", {
            get: $util.oneOfGetter($oneOfFields = ["sortColumn"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _sortOrder.
         * @member {"sortOrder"|undefined} _sortOrder
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_sortOrder", {
            get: $util.oneOfGetter($oneOfFields = ["sortOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _pageIndex.
         * @member {"pageIndex"|undefined} _pageIndex
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_pageIndex", {
            get: $util.oneOfGetter($oneOfFields = ["pageIndex"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _pageSize.
         * @member {"pageSize"|undefined} _pageSize
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_pageSize", {
            get: $util.oneOfGetter($oneOfFields = ["pageSize"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _quoteNo.
         * @member {"quoteNo"|undefined} _quoteNo
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_quoteNo", {
            get: $util.oneOfGetter($oneOfFields = ["quoteNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _rego.
         * @member {"rego"|undefined} _rego
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_rego", {
            get: $util.oneOfGetter($oneOfFields = ["rego"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _claimNo.
         * @member {"claimNo"|undefined} _claimNo
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_claimNo", {
            get: $util.oneOfGetter($oneOfFields = ["claimNo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * EmailSmsLogFilterModel _direction.
         * @member {"direction"|undefined} _direction
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @instance
         */
        Object.defineProperty(EmailSmsLogFilterModel.prototype, "_direction", {
            get: $util.oneOfGetter($oneOfFields = ["direction"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        /**
         * Encodes the specified EmailSmsLogFilterModel message. Does not implicitly {@link EmailSmsLogFilter.EmailSmsLogFilterModel.verify|verify} messages.
         * @function encode
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @static
         * @param {EmailSmsLogFilter.IEmailSmsLogFilterModel} m EmailSmsLogFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [w] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailSmsLogFilterModel.encode = function encode(m, w) {
            if (!w)
                w = $Writer.create();
            if (m.dateFrom != null && Object.hasOwnProperty.call(m, "dateFrom"))
                w.uint32(10).string(m.dateFrom);
            if (m.dateTo != null && Object.hasOwnProperty.call(m, "dateTo"))
                w.uint32(18).string(m.dateTo);
            if (m.type != null && Object.hasOwnProperty.call(m, "type"))
                w.uint32(26).string(m.type);
            if (m.status != null && Object.hasOwnProperty.call(m, "status"))
                w.uint32(34).string(m.status);
            if (m.sortColumn != null && Object.hasOwnProperty.call(m, "sortColumn"))
                w.uint32(42).string(m.sortColumn);
            if (m.sortOrder != null && Object.hasOwnProperty.call(m, "sortOrder"))
                w.uint32(50).string(m.sortOrder);
            if (m.pageIndex != null && Object.hasOwnProperty.call(m, "pageIndex"))
                w.uint32(56).int32(m.pageIndex);
            if (m.pageSize != null && Object.hasOwnProperty.call(m, "pageSize"))
                w.uint32(64).int32(m.pageSize);
            if (m.quoteNo != null && Object.hasOwnProperty.call(m, "quoteNo"))
                w.uint32(74).string(m.quoteNo);
            if (m.rego != null && Object.hasOwnProperty.call(m, "rego"))
                w.uint32(82).string(m.rego);
            if (m.claimNo != null && Object.hasOwnProperty.call(m, "claimNo"))
                w.uint32(90).string(m.claimNo);
            if (m.direction != null && Object.hasOwnProperty.call(m, "direction"))
                w.uint32(98).string(m.direction);
            return w;
        };

        /**
         * Encodes the specified EmailSmsLogFilterModel message, length delimited. Does not implicitly {@link EmailSmsLogFilter.EmailSmsLogFilterModel.verify|verify} messages.
         * @function encodeDelimited
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @static
         * @param {EmailSmsLogFilter.IEmailSmsLogFilterModel} message EmailSmsLogFilterModel message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        EmailSmsLogFilterModel.encodeDelimited = function encodeDelimited(message, writer) {
            return this.encode(message, writer).ldelim();
        };

        /**
         * Decodes an EmailSmsLogFilterModel message from the specified reader or buffer.
         * @function decode
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} r Reader or buffer to decode from
         * @param {number} [l] Message length if known beforehand
         * @returns {EmailSmsLogFilter.EmailSmsLogFilterModel} EmailSmsLogFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailSmsLogFilterModel.decode = function decode(r, l) {
            if (!(r instanceof $Reader))
                r = $Reader.create(r);
            var c = l === undefined ? r.len : r.pos + l, m = new $root.EmailSmsLogFilter.EmailSmsLogFilterModel();
            while (r.pos < c) {
                var t = r.uint32();
                switch (t >>> 3) {
                case 1:
                    m.dateFrom = r.string();
                    break;
                case 2:
                    m.dateTo = r.string();
                    break;
                case 3:
                    m.type = r.string();
                    break;
                case 4:
                    m.status = r.string();
                    break;
                case 5:
                    m.sortColumn = r.string();
                    break;
                case 6:
                    m.sortOrder = r.string();
                    break;
                case 7:
                    m.pageIndex = r.int32();
                    break;
                case 8:
                    m.pageSize = r.int32();
                    break;
                case 9:
                    m.quoteNo = r.string();
                    break;
                case 10:
                    m.rego = r.string();
                    break;
                case 11:
                    m.claimNo = r.string();
                    break;
                case 12:
                    m.direction = r.string();
                    break;
                default:
                    r.skipType(t & 7);
                    break;
                }
            }
            return m;
        };

        /**
         * Decodes an EmailSmsLogFilterModel message from the specified reader or buffer, length delimited.
         * @function decodeDelimited
         * @memberof EmailSmsLogFilter.EmailSmsLogFilterModel
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @returns {EmailSmsLogFilter.EmailSmsLogFilterModel} EmailSmsLogFilterModel
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        EmailSmsLogFilterModel.decodeDelimited = function decodeDelimited(reader) {
            if (!(reader instanceof $Reader))
                reader = new $Reader(reader);
            return this.decode(reader, reader.uint32());
        };

        return EmailSmsLogFilterModel;
    })();

    return EmailSmsLogFilter;
})();

module.exports = $root;
